import * as d3 from "d3"
export default function SketchZenNetwork(p5) {
  var mass = []
  var positionX = []
  var positionY = []
  var positionZ = []
  var velocityX = []
  var velocityY = []
  var velocityZ = []
  var fr = 60
  var canvasWidth
  /////////////////////////////////////////////////////////////////////////////////////////////////////

  p5.setup = () => {
    const calcCanvasSize = () => {
      const windowWidth = p5.windowWidth
      const windowHeight = p5.windowHeight
      let minDimension = d3.min([windowWidth, windowHeight])
      // let minDimension = windowHeight
      if (1500 <= minDimension) {
        // minDimension = minDimension * 0.9 * 0.6
        minDimension = minDimension * 0.8
      } else if ((676 <= minDimension) & (minDimension <= 1170)) {
        minDimension = minDimension * 0.8
      } else {
        minDimension = minDimension
      }
      return minDimension
    }
    const numberOfNode = 40
    // const c = p5.createCanvas(p5.displayWidth, p5.displayHeight, p5.WEBGL)
    canvasWidth = calcCanvasSize()
    const c = p5.createCanvas(canvasWidth, canvasWidth, p5.WEBGL)
    p5.noStroke()
    p5.fill(64, 255, 255, 192)
    p5.frameRate(fr)
    ;[...Array(numberOfNode)].forEach(d => {
      mass.push(p5.random(0.003, 0.03))
      positionX.push(p5.random(-50, 50))
      positionY.push(p5.random(-50, 50))
      positionZ.push(p5.random(-50, 50))
      velocityX.push(0)
      velocityY.push(0)
      velocityZ.push(0)
    })
  }

  p5.draw = () => {
    p5.background("rgba(0, 0, 0, 0.8)")
    p5.orbitControl() //drag to move the world.

    p5.ambientLight(180)
    p5.directionalLight(100, 1, 0, 0)

    mass.forEach((_, idxThis) => {
      var accelerationX = 0
      var accelerationY = 0
      var accelerationZ = 0
      mass.forEach((__, idxOther) => {
        if (idxThis != idxOther) {
          var distanceX = positionX[idxOther] - positionX[idxThis]
          var distanceY = positionY[idxOther] - positionY[idxThis]
          var distanceZ = positionZ[idxOther] - positionZ[idxThis]

          var distance = p5.sqrt(
            distanceX * distanceX +
              distanceY * distanceY +
              distanceZ * distanceZ
          ) // あるNodeを起点に他のParticleそれぞれとの距離を算出．
          if (distance < 1) distance = 1

          var force =
            ((distance - p5.random(canvasWidth / 3, canvasWidth / 3)) *
              mass[idxOther]) /
            distance

          // distanceが0に近いとかりForceが大きくなる（つまりマイナス方向にはかなり大きなForce．一方で，distanceが大きくても，微量の＋になるだけ）
          accelerationX += force * distanceX
          accelerationY += force * distanceY
          accelerationZ += force * distanceZ
        }
      })

      velocityX[idxThis] =
        velocityX[idxThis] * 0.99 + accelerationX * mass[idxThis] // ここで 0.99 をいれることで完全な釣り合いがなくても落ち着かせる感じかと．
      velocityY[idxThis] =
        velocityY[idxThis] * 0.99 + accelerationY * mass[idxThis]
      velocityZ[idxThis] =
        velocityZ[idxThis] * 0.99 + accelerationZ * mass[idxThis]
    })

    mass.forEach((_, idxThis) => {
      positionX[idxThis] += velocityX[idxThis]
      positionY[idxThis] += velocityY[idxThis]
      positionZ[idxThis] += velocityZ[idxThis]

      mass.forEach((_, idxOther) => {
        // stroke("#2233FF");
        if (mass[idxThis] >= mass[idxOther] && mass[idxThis] >= 0.01) {
          // ambientMaterial(255, 255, 0);
          p5.stroke("rgba(255, 255, 0, 0.3)")
          p5.strokeWeight(0.5)
          p5.line(
            positionX[idxThis],
            positionY[idxThis],
            positionZ[idxThis],
            positionX[idxOther],
            positionY[idxOther],
            positionZ[idxOther]
          )
        }
      })
      p5.push()
      p5.translate(positionX[idxThis], positionY[idxThis], positionZ[idxThis])
      p5.noStroke()
      p5.ambientMaterial("rgba(255, 255, 0, 0.9)")
      p5.sphere((mass[idxThis] * 1000) / 2)
      p5.pop()
    })
  }
  p5.mouseClicked = () => {
    mass.forEach((data, idx) => {
      velocityX[idx] = p5.random(-10, 10)
      velocityY[idx] = p5.random(-10, 10)
      velocityZ[idx] = p5.random(-10, 10)
    })
  }
}
